import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'section-hero',
  templateUrl: './section-hero.component.html',
  styleUrls: ['./section-hero.component.scss']
})
export class SectionHeroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
