import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SectionHeroComponent } from './section-hero/section-hero.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SectionProductosComponent } from './section-productos/section-productos.component';
import { SectionNosotrosComponent } from './section-nosotros/section-nosotros.component';
import { SectionServiciosComponent } from './section-servicios/section-servicios.component';
import { SectionProveedoresComponent } from './section-proveedores/section-proveedores.component';
import { SectionContactoComponent } from './section-contacto/section-contacto.component';
import { SectionFegimeComponent } from './section-fegime/section-fegime.component';
import { FormsModule } from '@angular/forms';
import { MainComponent } from './main/main.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', component: MainComponent},
  { path: 'nosotros', component: SectionNosotrosComponent},
  { path: 'proveedores', component: SectionProveedoresComponent},
  { path: 'contacto', component: SectionContactoComponent},
  { path: 'fegime', component: SectionFegimeComponent}
]


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SectionHeroComponent,
    TopBarComponent,
    SectionProductosComponent,
    SectionNosotrosComponent,
    SectionServiciosComponent,
    SectionProveedoresComponent,
    SectionContactoComponent,
    SectionFegimeComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
