import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'section-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  contactForm: FormGroup;
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('',[Validators.required,Validators.pattern(this.emailPattern)]),
      name: new FormControl('',[Validators.required]),
      message: new FormControl('',[Validators.required])
    })
  }

  constructor() {

    this.contactForm = this.createFormGroup();

   }

  ngOnInit() {

   }

  onResetForm(){
    this.contactForm.reset();
  }

  onSaveForm(){
    if(this.contactForm.valid){
      alert('Su mensaje a sido enviado con exito. A la brevedad un representante se estará contactando. Muchas gracias');
      this.onResetForm();
    } else {
      alert('Los datos ingresados no son correctos, por favor vuelva a intentarlo');
      this.onResetForm();
    }
  }

  // @HostListener("scroll", ['$event'])
  // onWindowScroll(){
  //   console.log('Holaa');
  // }

  // smoothScroll(target, duration) {
  //   var target = document.querySelector(target);
  //   var targetPosition = target.getBoundingClientRect().top;
  //   var startPosition = window.pageYOffset;

  //   // console.log(target);
  //   // console.log(targetPosition);
  //   console.log(startPosition);
  // }

  get name(){ return this.contactForm.get('name')};
  get email(){ return this.contactForm.get('email')};
  get message(){ return this.contactForm.get('message')};

  
}
