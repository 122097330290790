import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'section-servicios',
  templateUrl: './section-servicios.component.html',
  styleUrls: ['./section-servicios.component.scss']
})
export class SectionServiciosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
