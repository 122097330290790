import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'section-nosotros',
  templateUrl: './section-nosotros.component.html',
  styleUrls: ['./section-nosotros.component.scss']
})
export class SectionNosotrosComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  } 

}
