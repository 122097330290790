import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'section-proveedores',
  templateUrl: './section-proveedores.component.html',
  styleUrls: ['./section-proveedores.component.scss']
})
export class SectionProveedoresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
